<template>
  <div class="company-address-root-container">
    <div class="company-address-row">
      <span style="font-size: 23px; font-weight: bold; color: #343733"
        >联系地址</span
      >
      <span
        @click="add"
        style="
          font-size: 15px;
          font-weight: bold;
          color: #525793;
          cursor: pointer;
        "
        >添加一个地址</span
      >
    </div>
    <div class="company-address-main-container">
      <address-item
        v-for="item in addressList"
        :key="item.address_id"
        :data-source="item"
        @refresh-address-list="getAddressList"
      />
    </div>
    <company-address-drawer
      ref="companyAddressDrawer"
      @refresh-address-list="getAddressList"
    />
  </div>
</template>

<script>
import AddressItem from "../Item/AddressItem";
import CompanyAddressDrawer from "../Drawer/CompanyAddressDrawer";
import { mapActions } from "vuex";
export default {
  components: {
    "address-item": AddressItem,
    "company-address-drawer": CompanyAddressDrawer,
  },
  data() {
    return {
      company_id: 0,
      addressList: [],
    };
  },
  mounted() {
    this.company_id = this.$route.params.company_id;

    this.getAddressList();
  },
  methods: {
    ...mapActions({
      getAddressListAction: "getAddressList",
    }),
    add() {
      this.$refs.companyAddressDrawer.open(-1, "");
    },
    getAddressList() {
      this.getAddressListAction({
        employer_id: localStorage.getItem("employer_id"),
        company_id: this.company_id,
      })
        .then((res) => {
          this.addressList = res;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.company-address-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.company-address-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.company-address-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
</style>