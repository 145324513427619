<template>
  <div class="address-item-root-container">
    <img @click="edit" class="address-item-edit" :src="icons.edit" />
    <p style="font-size: 15px; font-weight: bold">{{ dataSource.address }}</p>
    <company-address-drawer
      ref="companyAddressDrawer"
      @refresh-address-list="$emit('refresh-address-list')"
    />
  </div>
</template>

<script>
import CompanyAddressDrawer from "../Drawer/CompanyAddressDrawer";
import * as icon from "../../../common/icon";
export default {
  components: {
    "company-address-drawer": CompanyAddressDrawer,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
      company_id: -1,
    };
  },
  mounted() {
    this.company_id = this.$route.params.company_id;
  },
  methods: {
    edit() {
      this.$refs.companyAddressDrawer.open(
        this.dataSource.address_id,
        this.dataSource.address
      );
    },
  },
};
</script>

<style scoped>
.address-item-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px 80px 20px 20px;
  margin-right: 60px;
  margin-bottom: 20px;
  width: 250px;
}
.address-item-edit {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
</style>