<template>
  <div class="company-bank-root-container">
    <div class="company-bank-row">
      <span style="font-size: 23px; font-weight: bold; color: #343733"
        >银行账户信息</span
      >
      <span
        @click="add"
        style="
          font-size: 15px;
          font-weight: bold;
          color: #525793;
          cursor: pointer;
        "
        >添加一行</span
      >
    </div>
    <div>
      <account-item
        v-for="item in accountList"
        :key="item.account_id"
        :data-source="item"
        @refresh="getAccountList"
      />
    </div>
    <company-bank-drawer ref="companyBankDrawer" @refresh="getAccountList" />
  </div>
</template>

<script>
import AccountItem from "../Item/AccountItem";
import CompanyBankDrawer from "../Drawer/CompanyBankDrawer";
import { mapActions } from "vuex";
export default {
  components: {
    "company-bank-drawer": CompanyBankDrawer,
    "account-item": AccountItem,
  },
  data() {
    return {
      company_id: 0,
      accountList: [],
    };
  },
  mounted() {
    this.company_id = this.$route.params.company_id;

    this.getAccountList();
  },
  methods: {
    ...mapActions({
      getAccountListAction: "getAccountList",
    }),
    add() {
      this.$refs.companyBankDrawer.open();
    },
    getAccountList() {
      this.getAccountListAction({
        employer_id: localStorage.getItem("employer_id"),
        company_id: this.company_id,
      })
        .then((res) => {
          this.accountList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.company-bank-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.company-bank-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>