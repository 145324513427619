<template>
  <my-drawer ref="companyContactDrawer" title="添加联系人">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column" v-if="isShowDrawer">
        <RadioGroup v-model="contactForm.type" style="margin-bottom: 20px">
          <Radio :label="1">从内部成员选择</Radio>
          <Radio :label="2">新建联系人</Radio>
        </RadioGroup>
        <div v-if="contactForm.type == 1">
          <my-select-people v-model="contact_id" :data-source="memberList" />
          <!-- <Select v-model="contactForm.contact_id" @on-select="onSelectChange">
            <Option
              v-for="item in memberList"
              :value="item.to_user_id"
              :key="item.to_user_id"
              >{{ item.name }}</Option
            >
          </Select> -->
        </div>
        <div v-else>
          <my-input
            ref="formName"
            title="姓名"
            v-model="contactForm.name"
            :validate="nameValidate"
          />

          <my-input
            ref="formPhone"
            title="电话"
            v-model="contactForm.phone"
            :validate="phoneValidate"
          />

          <my-input
            ref="formEmail"
            title="邮箱"
            v-model="contactForm.email"
            :validate="emailValidate"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions } from "vuex";
import * as oss from "../../../utils/ossHelper";
import MyDrawer from "../../../components/MyDrawer";
import MyInput from "../../../components/Form/MyInput";
import MySelectPeople from "../../../components/Form/MySelectPeople";
export default {
  components: {
    "my-input": MyInput,
    "my-drawer": MyDrawer,
    "my-select-people": MySelectPeople,
  },
  data() {
    return {
      contact_id: 0,
      contactForm: {
        contact_id: -1,
        type: 1,
        name: "",
        phone: "",
        email: "",
        employer_id: localStorage.getItem("employer_id"),
        company_id: -1,
      },

      isShowDrawer: false,
      memberList: [],
    };
  },
  methods: {
    ...mapActions({
      employerGetUserListAction: "employerGetUserList",
      addOrUpdateContactAction: "addOrUpdateContact",
    }),
    open() {
      this.contact_id = 0;
      this.contactForm = {
        contact_id: -1,
        type: 1,
        name: "",
        phone: "",
        email: "",
        employer_id: localStorage.getItem("employer_id"),
        company_id: this.$route.params.company_id,
      };
      this.getUserList();
      this.isShowDrawer = true;
      this.$refs.companyContactDrawer.open();
    },
    getUserList() {
      this.employerGetUserListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.memberList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    submit() {
      if (this.contactForm.type == 1) {
        if (!this.contact_id) {
          this.$Message.warning("请选择联系人");
          return;
        } else {
          let item = this.memberList.find(
            (item) => item.contact_id == this.contact_id
          );
          this.contactForm.name = item.name;
          this.contactForm.phone = item.phone;
          this.contactForm.email = item.email;
          this.contactForm.img_url = item.img_url;

          this.addOrUpdateContactAction(this.contactForm)
            .then((res) => {
              this.$Message.success("添加联系人成功");
              this.$emit("refresh-contact-list");
              this.isShowDrawer = false;
              this.$refs.companyContactDrawer.close();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        }
      } else {
        Promise.all([
          this.$refs.formName.doValidate(),
          this.$refs.formPhone.doValidate(),
          this.$refs.formEmail.doValidate(),
        ])
          .then(() => {
            this.addOrUpdateContactAction(this.contactForm)
              .then((res) => {
                this.$Message.success("添加联系人成功");
                this.$emit("refresh-contact-list");
                this.$refs.companyContactDrawer.close();
              })
              .catch((error) => {
                this.$Message.error(error);
              });
          })
          .catch(() => {
            this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
          });
      }
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.contactForm.name == "") {
          reject("请填写姓名");
        } else {
          resolve();
        }
      });
    },
    phoneValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.contactForm.phone == "") {
          reject("请填写电话");
        } else {
          resolve();
        }
      });
    },
    emailValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.contactForm.email == "") {
          reject("请填写邮箱");
        } else {
          resolve();
        }
      });
    },
    onSelectChange(selectItem) {
      this.isSelectContact = true;
      let item = this.memberList.find(
        (item) => item.to_user_id == selectItem.value
      );
      this.contactForm.name = item.name;
      this.contactForm.phone = item.phone;
      this.contactForm.email = item.email;
    },
  },
};
</script>

<style scoped>
.employer-form-image {
  width: 100px;
  height: 118px;
}
</style>