<template>
  <div class="account-item-container" @click="edit">
    <my-avatar
      style="cursor: pointer"
      :size="50"
      :name="dataSource.bank_name"
      :img_url="dataSource.bank_img_url"
    />
    <div class="account-item-main-container">
      <span style="font-size: 15px; font-weight: bold; margin-bottom: 10px">{{
        dataSource.bank_name
      }}</span>
      <span style="font-size: 13px; font-weight: bold">{{
        dataSource.num
      }}</span>
    </div>
    <div class="account-item-right-container">
      <span style="font-size: 13px; font-weight: bold; margin-bottom: 10px"
        >备注</span
      >
      <span style="font-size: 15px; font-weight: bold">{{
        dataSource.more
      }}</span>
    </div>
    <img :src="icons.rightArrow" style="margin-left: 60px" />
    <company-bank-drawer ref="companyBankDrawer" @refresh="$emit('refresh')" />
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
import CompanyBankDrawer from "../Drawer/CompanyBankDrawer";
export default {
  components: {
    "my-avatar": MyAvatar,
    "company-bank-drawer": CompanyBankDrawer,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    edit() {
      // this.$refs.companyBankDrawer.open(this.dataSource.account_id);
      this.$refs.companyBankDrawer.open(this.dataSource);
    },
  },
};
</script>

<style scoped>
.account-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 13px;
  cursor: pointer;
}
.account-item-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.account-item-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>