<template>
  <div class="company-contact-root-container">
    <div class="company-contact-row">
      <span style="font-size: 23px; font-weight: bold; color: #343733"
        >联系人</span
      >
      <span
        @click="add"
        style="
          font-size: 15px;
          font-weight: bold;
          color: #525793;
          cursor: pointer;
        "
        >添加一个联系人</span
      >
    </div>
    <div>
      <contact-item
        v-for="item in contactList"
        :key="item.contact_id"
        :data-source="item"
      />
    </div>
    <company-contact-drawer
      ref="companyContactDrawer"
      @refresh-contact-list="getContactList"
    />
  </div>
</template>

<script>
import ContactItem from "../Item/ContactItem";
import CompanyContactDrawer from "../Drawer/CompanyContactDrawer";
import { mapActions } from "vuex";
export default {
  components: {
    "contact-item": ContactItem,
    "company-contact-drawer": CompanyContactDrawer,
  },
  data() {
    return {
      company_id: 0,
      contactList: [],
    };
  },
  mounted() {
    this.company_id = this.$route.params.company_id;

    this.getContactList();
  },
  methods: {
    ...mapActions({
      getContactListAction: "getContactList",
    }),
    add() {
      this.$refs.companyContactDrawer.open("");
    },
    getContactList() {
      this.getContactListAction({
        employer_id: localStorage.getItem("employer_id"),
        company_id: this.company_id,
      })
        .then((res) => {
          this.contactList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.company-contact-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.company-contact-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>