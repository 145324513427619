<template>
  <div class="employer-company-root-container">
    <div
      class="employer-company-background"
      :style="{ 'background-image': 'url(' + icons.employerBackground + ')' }"
    ></div>
    <div class="employer-company-scroll-wrapper">
      <div class="employer-company-left-container">
        <company-base />
        <company-service style="margin-top: 60px" />
      </div>
      <div class="employer-company-right-container">
        <company-business />
        <company-bank style="margin-top: 40px" />
        <company-address style="margin-top: 40px" />
        <company-contact style="margin-top: 40px" />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyContact from "./Panel/CompanyContact";
import CompanyAddress from "./Panel/CompanyAddress";
import CompanyBank from "./Panel/CompanyBank";
import CompanyBusiness from "./Panel/CompanyBusiness";
import CompanyService from "./Panel/CompanyService";
import CompanyBase from "./Panel/CompanyBase";
import * as eventHelper from "../../utils/eventHelper";
import * as icon from "../../common/icon";
import { mapActions } from "vuex";
export default {
  components: {
    "company-service": CompanyService,
    "company-base": CompanyBase,
    "company-business": CompanyBusiness,
    "company-bank": CompanyBank,
    "company-address": CompanyAddress,
    "company-contact": CompanyContact,
  },
  data() {
    return {
      icons: icon,
    };
  },
  mounted() {
    this.company_id = this.$route.params.company_id;

    this.getCompanyBasic();
  },
  methods: {
    ...mapActions({
      getCompanyBasicAction: "getCompanyBasic",
    }),
    getCompanyBasic() {
      this.getCompanyBasicAction({
        employer_id: localStorage.getItem("employer_id"),
        company_id: this.company_id,
      })
        .then((res) => {
          eventHelper.triggerEvent("changeTitle", res.name);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.employer-company-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px 115px 20px 115px;
  background-color: #f7f7f7;
}
.employer-company-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 340px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.employer-company-scroll-wrapper {
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  z-index: 2;
}
.employer-company-left-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 340px;
  margin-right: 60px;
}
.employer-company-right-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}
</style>