<template>
  <div class="contact-item-container">
    <my-avatar
      style="cursor: pointer;"
      :size="50"
      :name="dataSource.name"
      :img_url="dataSource.img_url"
    />
    <div class="contact-item-main-container">
      <span style="font-size: 15px;font-weight:bold;margin-bottom:10px;">{{dataSource.name}}</span>
      <div class="contact-item-row">
        <img :src="icons.phone" style="margin-right:10px"/>
        <span style="font-size: 13px;color:#5C5C5C;margin-right:30px;">{{dataSource.phone}}</span>
        <img :src="icons.email" style="margin-right:10px"/>
        <span style="font-size: 13px;color:#5C5C5C;">{{dataSource.email}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.contact-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 13px;
}
.contact-item-main-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.contact-item-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>