<template>
  <div class="company-business-root-container">
    <span style="font-size: 15px; font-weight: bold">工商信息</span>

    <div class="company-business-main-container">
      <table
        border="1"
        cellspacing="0"
        bordercolor="#ccc"
        style="border-collapse: collapse"
      >
        <tr>
          <td class="company-table-cell-blue">法定代表人</td>
          <td class="company-table-cell">{{ baseInfo.name }}</td>
          <td class="company-table-cell-blue">登记状态</td>
          <td class="company-table-cell">{{ baseInfo.state }}</td>
          <td class="company-table-cell-blue">成立日期</td>
          <td class="company-table-cell">{{ baseInfo.foundationDate }}</td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">注册资本</td>
          <td class="company-table-cell">{{ baseInfo.registeredCapital }}</td>
          <td class="company-table-cell-blue">实缴资本</td>
          <td class="company-table-cell">{{ baseInfo.paidUpCapital }}</td>
          <td class="company-table-cell-blue">核准日期</td>
          <td class="company-table-cell">{{ baseInfo.approvedDate }}</td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">统一社会信用代码</td>
          <td class="company-table-cell">
            {{ baseInfo.uniformSocialCreditCode }}
          </td>
          <td class="company-table-cell-blue">组织机构代码</td>
          <td class="company-table-cell">{{ baseInfo.organizationCode }}</td>
          <td class="company-table-cell-blue">工商注册号</td>
          <td class="company-table-cell">{{ baseInfo.No }}</td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">纳税人识别号</td>
          <td class="company-table-cell">{{ baseInfo.numberTaxPayer }}</td>
          <td class="company-table-cell-blue">进出口企业代码</td>
          <td class="company-table-cell">
            {{ baseInfo.importAndExportEnterpriseCode }}
          </td>
          <td class="company-table-cell-blue">所属行业</td>
          <td class="company-table-cell">{{ baseInfo.business }}</td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">企业类型</td>
          <td class="company-table-cell">{{ baseInfo.businessType }}</td>
          <td class="company-table-cell-blue">营业期限</td>
          <td class="company-table-cell">{{ baseInfo.businessTerm }}</td>
          <td class="company-table-cell-blue">登记机关</td>
          <td class="company-table-cell">
            {{ baseInfo.registrationAuthority }}
          </td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">人员规模</td>
          <td class="company-table-cell">{{ baseInfo.staffSize }}</td>
          <td class="company-table-cell-blue">参保人数</td>
          <td class="company-table-cell">{{ baseInfo.insuredNumber }}</td>
          <td class="company-table-cell-blue">所属地区</td>
          <td class="company-table-cell">{{ baseInfo.area }}</td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">曾用名</td>
          <td class="company-table-cell">{{ baseInfo.formerName }}</td>
          <td class="company-table-cell-blue">英文名</td>
          <td class="company-table-cell" colspan="3">
            {{ baseInfo.englishName }}
          </td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">企业地址</td>
          <td class="company-table-cell" colspan="5">{{ baseInfo.address }}</td>
        </tr>
        <tr>
          <td class="company-table-cell-blue">经营范围</td>
          <td class="company-table-cell" colspan="5">
            {{ baseInfo.businessScope }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      baseInfo: {
        name: "",
        state: "",
        foundationDate: "",
        registeredCapital: "",
        paidUpCapital: "-",
        approvedDate: "",
        uniformSocialCreditCode: "",
        organizationCode: "",
        businessRegistrationNo: "",
        numberTaxPayer: "",
        importAndExportEnterpriseCode: "-",
        business: "",
        businessType: "",
        businessTerm: "",
        registrationAuthority: "",
        staffSize: "",
        insuredNumber: "",
        area: "",
        formerName: "-",
        englishName: "-",
        address: "",
        businessScope: "",
      },
    };
  },
  mounted() {
    this.getCompanyInfoAction({
      employer_id: localStorage.getItem("employer_id"),
      company_id: this.$route.params.company_id,
    })
      .then((res) => {
        let data = JSON.parse(res.info);
        if (data.Result) {
          data = data.Result;
          let TermStart = "-";
          if (data.TermStart != "") {
            TermStart = moment(data.TermStart).format("YYYY-MM-DD");
          }
          let TeamEnd = "-";
          if (data.TeamEnd) {
            TeamEnd = moment(data.TeamEnd).format("YYYY-MM-DD");
          }
          this.baseInfo = {
            name: data.OperName,
            state: data.Status,
            No: data.No,
            foundationDate: moment(data.StartDate).format("YYYY-MM-DD"),
            registeredCapital: data.RegistCapi,
            paidUpCapital: data.RecCap,
            approvedDate: moment(data.CheckDate).format("YYYY-MM-DD"),
            uniformSocialCreditCode: data.CreditCode,
            organizationCode: data.OrgNo,
            businessRegistrationNo: "",
            numberTaxPayer: data.CreditCode,
            importAndExportEnterpriseCode: "",
            business: "",
            businessType: data.EconKind,
            businessTerm: TermStart + "至" + TeamEnd,
            registrationAuthority: data.BelongOrg,
            staffSize: "",
            insuredNumber: "",
            area: data.Province,
            formerName:
              data.OriginalName.length > 0 ? data.OriginalName[0].Name : "",
            englishName: "",
            address: data.Address,
            businessScope: data.Scope,
          };
        }
      })
      .catch((error) => {
        console.log(error);
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      getCompanyInfoAction: "getCompanyInfo",
    }),
  },
};
</script>

<style scoped>
.company-business-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  background-color: #fff;
  border-radius: 14px;
}
.company-business-main-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.company-table-cell-blue {
  background-color: #f0f8fc;
  font-size: 12px;
  padding: 10px;
}
.company-table-cell {
  font-size: 12px;
  padding: 10px;
}
</style>