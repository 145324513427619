<template>
  <my-drawer ref="companyAddressDrawer" title="编辑地址信息">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-textarea
          ref="formAddress"
          title="地址"
          :rows="10"
          v-model="address"
          :validate="addressValidate"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions } from "vuex";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
  },
  data() {
    return {
      address: "",
      address_id: -1,
    };
  },
  methods: {
    ...mapActions({
      addOrUpdateAddressAction: "addOrUpdateAddress",
    }),
    open(address_id, address) {
      this.address_id = address_id;
      this.address = address;
      this.$refs.companyAddressDrawer.open();
    },
    submit() {
      Promise.all([this.$refs.formAddress.doValidate()])
        .then(() => {
          this.addOrUpdateAddressAction({
            employer_id: localStorage.getItem("employer_id"),
            company_id: this.$route.params.company_id,
            address_id: this.address_id,
            address: this.address,
          })
            .then((res) => {
              this.$Message.success("添加成功");
              this.$emit("refresh-address-list");
              this.$refs.companyAddressDrawer.close();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    addressValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.address == "") {
          reject("请填写地址");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>