<template>
  <my-drawer ref="companyBankDrawer" title="添加银行账户信息">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <span style="margin-bottom: 10px; font-weight: bold">选择银行</span>
        <Select v-model="bankForm.bank_id" @on-change="bankChange" size="large" style="margin-bottom: 15px">
          <Avatar :src="currentBank.img_url" slot="prefix" size="small" />
          <Option v-for="item in bankList" :value="item.bank_id" :key="item.bank_id">
            <Avatar :src="item.img_url" size="small" />
            {{ item.name }}
          </Option>
        </Select>

        <my-input ref="formName" title="开户行" v-model="bankForm.sub_name" :validate="nameValidate" />

        <my-input ref="formNum" title="卡号" v-model="bankForm.num" :validate="numValidate" />

        <my-textarea ref="formDesc" title="描述" :rows="4" v-model="bankForm.more" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit">保存</Button>
    </template>
  </my-drawer>
</template>

<script>
import * as oss from "../../../utils/ossHelper";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyInput from "../../../components/Form/MyInput";
import MyDrawer from "../../../components/MyDrawer";
import { mapActions } from "vuex";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
    "my-textarea": MyTextArea,
  },
  data() {
    return {
      currentBank: {
        img_url: "",
      },
      bankList: [],
      bankForm: {
        employer_id: localStorage.getItem("employer_id"),
        company_id: -1,
        account_id: -1,
        bank_id: 0,
        sub_name: "",
        num: "",
        more: "",
      },
    };
  },
  methods: {
    ...mapActions({
      getBankListAction: "getBankList",
      addOrUpdateAccountAction: "addOrUpdateAccount",
    }),
    open(dataSource) {
      this.getBankListAction()
        .then((res) => {
          this.bankList = res;

          if (dataSource) {
            this.bankForm.employer_id = localStorage.getItem("employer_id");
            this.bankForm.company_id = this.$route.params.company_id;
            this.bankForm.account_id = dataSource.account_id;
            this.bankForm.bank_id = dataSource.bank_id;
            this.bankForm.sub_name = dataSource.sub_name;
            this.bankForm.num = dataSource.num;
            this.bankForm.more = dataSource.more;

            this.currentBank = this.bankList.find((item) => {
              return item.bank_id == dataSource.bank_id;
            });
          } else {
            this.bankForm.employer_id = localStorage.getItem("employer_id");
            this.bankForm.company_id = this.$route.params.company_id;
            this.bankForm.account_id = -1;
            this.bankForm.bank_id = 0;
            this.bankForm.sub_name = "";
            this.bankForm.num = "";
            this.bankForm.more = "";

            this.currentBank = {
              img_url: "",
            };
          }
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });

      this.$refs.companyBankDrawer.open();
    },
    bankChange(id) {
      if (id == undefined) {
        return;
      }
      this.currentBank = this.bankList.find((item) => {
        return item.bank_id == id;
      });
    },
    submit() {
      if (this.bankForm.bank_id == 0) {
        this.$Message.warning("请选择银行");
        return;
      }
      Promise.all([
        this.$refs.formName.doValidate(),
        this.$refs.formNum.doValidate(),
      ])
        .then(() => {
          this.addOrUpdateAccountAction(this.bankForm)
            .then((res) => {
              let msg =
                this.bankForm.account_id == -1 ? "添加成功" : "修改成功";
              this.$Message.success(msg);
              this.$emit("refresh");
              this.$refs.companyBankDrawer.close();
            })
            .catch((error) => {
              console.log(error);
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.bankForm.sub_name == "") {
          reject("请填写支行");
        } else {
          resolve();
        }
      });
    },
    numValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.bankForm.num == "") {
          reject("请填写卡号");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.employer-form-image {
  width: 100px;
  height: 118px;
}
</style>